import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UsersPage from './UsersPage';
import ReportsPage from './ReportsPage';
import DashboardPage from './DashboardPage';
import UploadXmlsPage from './UploadXmlsPage';
import ProcessReportPage from './ProcessReportPage';
import { useNavigate, useParams } from 'react-router-dom';
import AssessorPaymentsPage from './AssessorPaymentsPage';
import IncompleteReportsPage from './IncompleteReportsPage';
import IncompleteAppointmentsPage from './IncompleteAppointmentsPage';
import RaiseInvoicePage from './RaiseInvoicePage';

function HomePage(props) {

  const navigate = useNavigate()
  const routeParams = useParams()

  const setActiveTab = (activeTab) => {
    if (activeTab === 'dashboard') {
      navigate('/home')
    } else if (activeTab === 'incompleteReports') {
      navigate('/incompleteReports')
    } else if (activeTab === 'preliminaryReports') {
      navigate('/preliminaryReports')
    } else if (activeTab === 'premiumReports') {
      navigate('/premiumReports')
    } else if (activeTab === 'users') {
      navigate('/users')
    } else if (activeTab === 'questionnaireReports') {
      navigate('/questionnaireReports')
    } else if (activeTab === 'incompleteAppointments') {
      navigate('/appointments')
    } else if (activeTab === 'assessorPayments') {
      navigate('/assessorPayments')
    } else if (activeTab === 'raiseInvoice') {
      navigate('/raiseInvoice')
    }
  }

  return (
    <div className="d-flex justify-content-between">
        <div className="flex-fill">
          <div className="card">
            <div className="card-header">
              <strong>Admin area</strong> <button onClick={props.doLogout} type="submit" className="btn btn-primary btn-sm">Log out</button>
            </div>
            <div className="card-body">
              <Tabs transition={false} activeKey={props.activeTab} onSelect={(tab) => setActiveTab(tab)} id="homepage-tabs" unmountOnExit={true} mountOnEnter={true} className="mb-3">
                <Tab eventKey="dashboard" title="Dashboard">
                  <DashboardPage />
                </Tab>
                <Tab eventKey="users" title="Users">
                  <UsersPage />
                </Tab>
                <Tab eventKey="incompleteReports" title="Incomplete reports">
                  { props.route === "incompleteReports" ? <IncompleteReportsPage /> : null }
                  { props.route === "processReport" ? <ProcessReportPage mode="premium" /> : null }
                </Tab>
                <Tab eventKey="preliminaryReports" title="Preliminary Reports">
                  <ReportsPage reportMode="free" />
                </Tab>
                <Tab eventKey="premiumReports" title="Premium Reports">
                  { props.route === 'premiumReports' ? <ReportsPage reportMode="premium" /> : null }
                  { props.route === 'newPremiumReport' ? <UploadXmlsPage mode="new" /> : null }
                </Tab>
                <Tab eventKey="questionnaireReports" title="Questionnaire Reports">
                  <ReportsPage reportMode="questionnaire-free" />
                </Tab>
                <Tab eventKey="incompleteAppointments" title="Appointments">
                  { props.route === 'incompleteAppointments' ? <IncompleteAppointmentsPage /> : null }
                  { props.route === 'uploadAppointmentXmls' ? <UploadXmlsPage mode="appointment" acuityId={routeParams.acuityId} /> : null }
                  { props.route === 'processAppointment' ? <ProcessReportPage mode="appointmentPremium" /> : null }
                </Tab>
                <Tab eventKey="assessorPayments" title="Assessor Payments">
                  <AssessorPaymentsPage />
                </Tab>
                <Tab eventKey="raiseInvoice" title="Raise Invoice">
                  <RaiseInvoicePage />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
  );

}

export default HomePage;
