import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function PremiumReportCreditsButton(props)
{
    const [show, setShow] = useState(false)
    const [gotValue, setGotValue] = useState(false)
    const [credits, setCredits] = useState(0)
    const [numberToAdd, setNumberToAdd] = useState(0)

    const handleClose = () => {
        setShow(false)
        setGotValue(false)
    }

    const handleShow = () => {
        setShow(true)
        setNumberToAdd(0)
        const url = process.env.REACT_APP_API_END_POINT + 'premiumreportcredits';
        fetch(url, {
              headers: {
                'Accept': 'application/json',
              },
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                username: props.user.username
              })
            })
            .then((resp) => resp.json())
            .then((data) => {
                if (data['success']) {
                    setGotValue(true)
                    setCredits(data['premium_report_credits'])
                }
            }
        );
    }

    const doAddCredits = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'premiumreportcredits';
        fetch(url, {
              headers: {
                'Accept': 'application/json',
              },
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                username: props.user.username,
                award: numberToAdd
              })
            })
            .then((resp) => resp.json())
            .then((data) => {
                if (data['success']) {
                    handleClose()
                }
            }
        );
    }

    return (<>
        <Button className="btn btn-sm" onClick={handleShow}><FontAwesomeIcon icon={solid("award")} style={{color: "#FFFFFF"}} /></Button>
        <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Premium Report Credits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {gotValue && <>
                <p>The user <strong>{props.user.username}</strong> has <strong>{credits}</strong> Premium Report {credits === 1 ? 'credit' : 'credits'} remaining.</p>
                <p>Credits to add: <input type="number" value={numberToAdd === 0 ? "" : numberToAdd} style={{width: "50px"}} onChange={e => setNumberToAdd(parseInt(e.target.value))} /></p>
            </>}
            {!gotValue && <div className="my-5" style={{ textAlign: "center" }}><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} /></div>}
          </Modal.Body>
          <Modal.Footer>
            {gotValue && numberToAdd !== 0 && <>
                <Button variant="primary" onClick={doAddCredits}>Add {numberToAdd} {numberToAdd === 1 ? 'credit' : 'credits'}</Button>
            </>}
            {/* <Button disabled={sendInProgress} variant="primary" onClick={sendEmail}>
              {
                sendInProgress ?
                  <><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000"}} />&nbsp;Sending</>
                :
                  <>Send</>
              }
            </Button> */}
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>)
}

export default PremiumReportCreditsButton;