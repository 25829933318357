import { useState } from "react";
import React, { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function RaiseInvoicePage()
{
    const [lineItems, setLineItems] = useState([])
    const [discountItems, setDiscountItems] = useState([])
    
    const [customerName, setCustomerName] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')

    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [addressCity, setAddressCity] = useState('')
    const [addressCounty, setAddressCounty] = useState('')
    const [addressPostCode, setAddressPostCode] = useState('')

    const [currentItemName, setCurrentItemName] = useState('')
    const [currentItemPrice, setCurrentItemPrice] = useState('')
    const [currentDiscountAmount, setCurrentDiscountAmount] = useState()
    const [currentDiscountName, setCurrentDiscountName] = useState('')

    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)

    const [working, setWorking] = useState(false)
    const [invoiceUrl, setInvoiceUrl] = useState(null)
    const [copied, setCopied] = useState(false)

    const raiseInvoiceInStripe = () => {
        setWorking(true)
        setInvoiceUrl(null)
        var fUrl = process.env.REACT_APP_API_END_POINT + 'adminraisestripeinvoice/';
      
        fetch(fUrl,
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                customerName, customerEmail,
                lineItems,
                discountItems,
                addressLine1, addressLine2, addressCity, addressCounty, addressPostCode
            })
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
                setWorking(false)
                setInvoiceUrl(data['invoice_url'])
            } else {

            }
          }
        );
    }

    const addItem = () => {
        var amount = parseFloat(currentItemPrice)
        if (isNaN(amount)) {
            alert("Please check the price")
            return
        }
        if (currentItemName.trim() === '') {
            alert("Please enter the item name")
            return
        }
        amount = parseInt(amount * 100) / 100
        const _lineItems = JSON.parse(JSON.stringify(lineItems))
        _lineItems.push({
            'name': currentItemName,
            'price': amount
        })
        setLineItems(_lineItems)
        setCurrentItemPrice('')
        setCurrentItemName('')
    }

    const addDiscount = () => {
        var amount = parseFloat(currentDiscountAmount)
        if (isNaN(amount)) {
            alert("Please check the discount amount")
            return
        }
        if (currentDiscountName.trim() === '') {
            alert("Please enter the discount name");
            return
        }
        amount = parseInt(amount * 100) / 100
        const _discountItems = JSON.parse(JSON.stringify(discountItems))
        _discountItems.push({
            'name': currentDiscountName,
            'amount': amount
        })
        setDiscountItems(_discountItems)
        setCurrentDiscountAmount('')
        setCurrentDiscountName('')
    }

    useEffect(() => {
        var _totalPrice = 0
        lineItems.forEach(i => {
            _totalPrice += i.price
        })
        setTotalPrice(_totalPrice)
        
        var _totaldiscount = 0
        discountItems.forEach(i => {
            _totaldiscount += i.amount
        })
        setTotalDiscount(_totaldiscount)

        setGrandTotal(_totalPrice - _totaldiscount)
    }, [lineItems, discountItems])

    useEffect(() => {
        setCopied(false)
        setInvoiceUrl(null)
    }, [lineItems, discountItems, customerName, customerEmail, addressLine1, addressLine2, addressCity, addressCounty, addressPostCode])

    return (
        <>
            <div className="adminInvoiceDataEntry">
                <div className="section">
                    <span className="heading">Customer details:</span>
                    <label htmlFor="nameInput">Name:</label>
                    <input id="nameInput" type="text" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                    <label htmlFor="emailInput">Email:</label>
                    <input id="emailInput" type="email" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)} />
                    <span className="heading">Address:</span>
                    <label htmlFor="addressLine1">Line 1:</label>
                    <input id="addressLine1" type="text" value={addressLine1} onChange={e => setAddressLine1(e.target.value)} />
                    <label htmlFor="addressLine2">Line 2:</label>
                    <input id="addressLine2" type="text" value={addressLine2} onChange={e => setAddressLine2(e.target.value)} />
                    <label htmlFor="addressCity">Town / city:</label>
                    <input id="addressCity" type="text" value={addressCity} onChange={e => setAddressCity(e.target.value)} />
                    <label htmlFor="addressCounty">County:</label>
                    <input id="addressCounty" type="text" value={addressCounty} onChange={e => setAddressCounty(e.target.value)} />
                    <label htmlFor="addressPostCode">Post code:</label>
                    <input id="addressPostCode" type="text" value={addressPostCode} onChange={e => setAddressPostCode(e.target.value)} />
                    <label htmlFor="country">Country:</label>
                    <span>UK addresses only!</span>
                </div>
                
                <div className="section addItemSection">
                    <span className="heading">Add item:</span>
                    <span>
                        <label htmlFor="itemName">Item:</label>
                        <input id="itemName" type="text" value={currentItemName} onChange={e => setCurrentItemName(e.target.value)} />
                    </span>
                    <span>
                        <label htmlFor="itemPrice">Price &pound;:</label>
                        <input id="itemPrice" type="number" value={currentItemPrice} onChange={e => setCurrentItemPrice(e.target.value)} />
                    </span>
                    <button id="addItem" onClick={addItem} className="btn btn-primary btn-sm">Add</button>
                </div>

                <div className="section addItemSection">
                    <span className="heading">Add discount:</span>
                    <span>
                        <label htmlFor="discountName">Item:</label>
                        <input id="discountName" type="text" value={currentDiscountName} onChange={e => setCurrentDiscountName(e.target.value)} />
                    </span>
                    <span>
                        <label htmlFor="discountAmount">Price &pound;:</label>
                        <input id="discountAmount" type="number" value={currentDiscountAmount} onChange={e => setCurrentDiscountAmount(e.target.value)} />
                    </span>
                    <button id="addDiscount" onClick={addDiscount} className="btn btn-primary btn-sm">Add</button>
                </div>
            </div>

            <p><strong>Invoice:</strong></p>
            <div className="adminInvoice" style={{display: "grid", gridTemplateColumns: "max-content max-content", marginBottom: "18px"}}>
                { lineItems.length !== 0 && <>
                    <div style={{gridColumn: "span 2", textAlign: "center"}}>Items</div>
                    { lineItems.map(item => {
                        return (
                            <>
                                <div style={{paddingInline: "18px", textAlign: "right"}}>{item.name}</div>
                                <div style={{paddingInline: "10px", textAlign: "right"}}>&pound;{item.price.toFixed(2)}</div>
                            </>
                        )
                    })}
                </> }

                <div style={{fontWeight: "bold", paddingInline: "10px 18px", textAlign: "right"}}>Subtotal:</div>
                <div style={{paddingInline: "10px", textAlign: "right"}}>&pound;{totalPrice.toFixed(2)}</div>

                { discountItems.length !== 0 && <>
                    <div style={{gridColumn: "span 2", textAlign: "center"}}>Discounts</div>
                    { discountItems.map(item => {
                        return (
                            <>
                                <div style={{paddingRight: "18px", textAlign: "right"}}>{item.name}</div>
                                <div style={{paddingInline: "10px", textAlign: "right"}}>-&pound;{item.amount.toFixed(2)}</div>
                            </>
                        )
                    })}
                </> }

                <div style={{fontWeight: "bold", paddingInline: "10px 18px", textAlign: "right"}}>Discounts:</div>
                <div style={{paddingInline: "10px", textAlign: "right"}}>-&pound;{totalDiscount.toFixed(2)}</div>
                <div style={{fontWeight: "bold", paddingInline: "10px 18px", textAlign: "right"}}>Total:</div>
                <div style={{paddingInline: "10px", textAlign: "right"}}>&pound;{grandTotal.toFixed(2)}</div>
            </div>

    
            <button className="btn btn-primary btn-sm" disabled={working} onClick={raiseInvoiceInStripe}>Raise invoice in Stripe</button>
            {!(invoiceUrl === null) && <>
                <CopyToClipboard text={invoiceUrl} onCopy={() => setCopied(true)} >
                    {/* urlToCopy is on the button to make testing in playwright easier (it can read that), rather than getting it from the clipboard which is problematic */}
                    <button urlToCopy={invoiceUrl} className="mx-2 btn btn-primary btn-sm"><FontAwesomeIcon icon={solid("copy")} style={{color: "#FFFFFF"}} />&nbsp;&nbsp;&nbsp;Copy</button>
                </CopyToClipboard>{ copied ? <>Copied</> : null }
            </>}
        </>
    )
}

export default RaiseInvoicePage;