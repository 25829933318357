import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function PerUserPremiumDiscountButton(props)
{
    const originalDisplayPounds = Math.floor(props.user.premium_purchase_discount_pence / 100)
    const originalDisplayPence = props.user.premium_purchase_discount_pence % 100

    const [show, setShow] = useState(false)
    const [userSuppliedDiscount, setUserSuppliedDiscount] = useState()
    const [amountIsValid, setAmountIsValid] = useState(true)

    const handleClose = () => {
      setShow(false)
    }

    const handleShow = () => {
      setShow(true)
      setUserSuppliedDiscount(originalDisplayPounds + '.' + originalDisplayPence.toString().padStart(2, '0'))
    }

    const doSaveDiscount = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'adminsetuserdiscount';
        fetch(url, {
              headers: {
                'Accept': 'application/json',
              },
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                username: props.user.username,
                discount_pounds: userSuppliedDiscount
              })
            })
            .then((resp) => resp.json())
            .then((data) => {
                if (data['success']) {
                    handleClose()
                }
            }
        );
    }

    const handleTextChange = (e) => {
      setUserSuppliedDiscount(e.target.value)

      // match 0-3 digits of pounds, optional dot followed by further optional 0-2 digits of pence
      const amountRegex = /^\d{0,3}(\.\d{0,2})?$/;
      setAmountIsValid(amountRegex.test(e.target.value))
    }

    return (<>
      <Button style={{marginLeft: "10px"}} className="btn btn-sm" onClick={handleShow}><FontAwesomeIcon icon={solid("sterling-sign")} style={{color: "#FFFFFF"}} /></Button>
      <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Premium Report Discount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The user <strong>{props.user.username}</strong> has a Premium Report Discount of <strong>&pound;{originalDisplayPounds}.{originalDisplayPence.toString().padStart(2, '0')}</strong></p>
            <p>This applies when they are buying their own Premium Reports from inside the app</p>
            <p>Change it: <input type="text" value={userSuppliedDiscount} style={{width: "90px"}} onChange={e => handleTextChange(e)} /></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={doSaveDiscount} disabled={!amountIsValid}>Save</Button>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>)
}

export default PerUserPremiumDiscountButton;