import { useState } from "react";
import React, { useEffect } from "react";
import { subscribe, unsubscribe } from './events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function AssessorPaymentsPage(props)
{
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState('')

    const fetchCommissionData = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'agentearningsall/'
        fetch(url, {
            headers: {
                'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                setMonthlyBreakdown(data['earnings_by_month'])
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        fetchCommissionData();
        const handleUpdateMessage = (body) => {
            fetchCommissionData();
        }
        subscribe('newCommissionPayment', handleUpdateMessage)
        return () => {
            unsubscribe('newCommissionPayment', handleUpdateMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) return <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />

    return (
        <>
            <textarea id="copyableArea" style={{display: "none", position: "absolute", left: "-10000px"}}></textarea>
            <div className="payoutsReport">
                { monthlyBreakdown.map((mb) => { return (
                    <>
                        <div className="monthSummary cell">{mb.month} (&pound;{(mb.total_pence / 100).toFixed(2)} - {mb.payment_status}, from {mb.report_count} {mb.report_count === 1 ? 'payment' : 'payments'})</div>             
                        { mb.users.map((user) => { 
                            const copyText = user.entries.reduce((acc, cur) => {
                                return acc + "£" + (cur.amount_pence / 100).toFixed(2) + "\t" + cur.assessor_ref + "\t" + cur.time + "\t" + cur.address + "\r\n"
                            }, "Amount\tAssessor ref\tDate\tReport name\r\n");

                            return (
                            <>
                                <div className="userSummary cell">
                                    <p style={{marginBlock: "0", float: "left"}}>{user.name} {user.username} (&pound;{(user.total_pence / 100).toFixed(2)} from {user.report_count} {user.report_count === 1 ? 'payment' : 'payments'})</p>
                                    <p style={{marginBlock: "0", float: "right"}}>
                                        { copied === mb.month + " " + user.username? <>Copied&nbsp;</> : null }
                                        <FontAwesomeIcon icon={solid("copy")} style={{color: "#FFFFFF"}} onClick={
                                            () => {
                                                var ta = document.getElementById('copyableArea')
                                                ta.style.display = "block"
                                                ta.value = copyText
                                                ta.select()
                                                document.execCommand("copy")
                                                ta.style.display = "none"
                                                setCopied(mb.month + " " + user.username)
                                            }
                                        } />
                                    </p>
                                </div>
                                <div className="cell heading">Amount</div>
                                <div className="cell heading">Assessor Ref</div>
                                <div className="cell heading">Date &amp; Time</div>
                                <div className="cell heading">Address</div>
                                { user.entries.map((entry) => { return (
                                    <>
                                        <div className="cell">&pound;{(entry.amount_pence / 100).toFixed(2)}</div>
                                        <div className="cell">{entry.assessor_ref}</div>
                                        <div className="cell">{entry.time}</div>
                                        <div className="cell">{entry.address}</div>
                                    </>
                                )})}
                            </>
                        )})}
                    </>
                )})}
            </div>
        </>
    )
}

export default AssessorPaymentsPage;