import React, { useState } from 'react';
import PremiumReportCreditsButton from './PremiumReportCreditsButton'
import PerUserPremiumDiscountButton from './PerUserPremiumDiscountButton';



function UsersListItem(props) {

    const [hoverRow, setHoverRow] = useState(null);

    const lastLoginDate = new Date(props.user.last_login_date * 1000);
    const registrationDate = new Date(props.user.registration_date * 1000);
    const lastReportDate = new Date(props.user.last_report_date * 1000);

    const onClick = () => {

    }

    const createCellDiv = (key_suffix, style, contents) => {

        var classes = `dataGridCell ${props.user.username === hoverRow ? 'dataGridCellHover' : ''}`

        return (
            <div
                onMouseEnter={() => setHoverRow(props.user.username)}
                onMouseLeave={() => setHoverRow(null)}
                onClick={onClick}
                key={props.user.username + ' ' + key_suffix}
                className={classes}
                style={style}
            >
                {contents}
            </div>
        )
    }

    const premiumDiscountDisplayPounds = Math.floor(props.user.premium_purchase_discount_pence / 100)
    const premiumDiscountDisplayPence = props.user.premium_purchase_discount_pence % 100

    return (
        <>
            
            {createCellDiv('username', {textAlign: "left"}, props.user.username)}

            {createCellDiv('name', {textAlign: "left"}, props.user.name)}

            {createCellDiv('phone', {textAlign: "left"}, props.user.phone)}

            {createCellDiv('signup', {textAlign: "right"}, registrationDate.toLocaleDateString())}

            {createCellDiv('login', {textAlign: "right"}, props.user.last_login_date === 0 ? '' : lastLoginDate.toLocaleDateString() + " " + lastLoginDate.toLocaleTimeString())}

            {createCellDiv('inviteCode', {textAlign: "left"}, props.user.invite_code)}

            {createCellDiv('scheme', {textAlign: "left"}, props.user.scheme)}

            {createCellDiv('reportcount', {textAlign: "right"}, (props.user.preliminary_reports_generated === 0 ? '-' : props.user.preliminary_reports_generated) + ' / '  + (props.user.premium_reports_generated === 0 ? '-' : props.user.premium_reports_generated))}
            
            {createCellDiv('lastReportDate', {textAlign: "right"}, props.user.last_report_date === 0 ? '' : lastReportDate.toLocaleDateString() + " " + lastReportDate.toLocaleTimeString())}

            {createCellDiv('premiumDiscount', {textAlign: "right"}, <>{props.user.premium_purchase_discount_pence === 0 ? '-' : '£' + premiumDiscountDisplayPounds + '.' + premiumDiscountDisplayPence.toString().padStart(2, '0')}<PerUserPremiumDiscountButton user={props.user} /></>)}

            {createCellDiv('credits', {textAlign: "right"}, <PremiumReportCreditsButton user={props.user} />)}
        </>
    );
}

export default UsersListItem;