import { ProgressBar } from 'react-bootstrap';

function AWSEmailData(props) {

  const awsSesData = props.awsSesData;

  const percentageSesQuotaUsed = (100 * (awsSesData['sent'] / awsSesData['quota'])).toFixed(2);
  var sesDataGridTemplateColumns = "120px";
  awsSesData['stats'].forEach(() => {
    sesDataGridTemplateColumns = sesDataGridTemplateColumns + " 100px";
  });
  
  return (
    <div>
      <h1 className="adminDashboard">AWS SES Email sending statistics, updates ~hourly</h1>
      <h2 className="adminDashboard">Emails sent</h2>
      <p>Sent {awsSesData['sent']} email{awsSesData['sent'] === 1 ? null : "s"} of {awsSesData['quota']} daily quota</p>
      <div style={{width: "46%", display: 'inline-block'}}>
        <ProgressBar now={percentageSesQuotaUsed} />
      </div> 
      <div style={{display: 'inline-block'}}>
        &nbsp;{percentageSesQuotaUsed}%
      </div>
      <h2 className="mt-3 adminDashboard">Bouncebacks and complaints</h2>
      <p>Data for last 2 weeks, days with no data are skipped</p>
      <div style={{display: "grid", gridTemplateColumns: sesDataGridTemplateColumns, rowGap: "1px", columnGap: "1px"}}>
        <div style={{backgroundColor: "#9898FF"}}></div>
        {
          awsSesData['stats'].map(data => {
            return <div style={{fontWeight: "bold", backgroundColor: "#9898FF"}}>{data['date']}</div>
          })
        } 
        <div style={{fontWeight: "bold"}}>Attempts</div>
        {
          awsSesData['stats'].map(data => {
            return (
              <div>{ data['attempts'] === 0 ? '-' : data['attempts'] }</div>
            )
          })
        }
        <div style={{fontWeight: "bold"}}>Bounces</div>
        {
          awsSesData['stats'].map(data => {
            return (
              <div>{ data['bounces'] === 0 ? '-' : data['bounces'] }</div>
            )
          })
        }
        <div style={{fontWeight: "bold"}}>Complaints</div>
        {
          awsSesData['stats'].map(data => {
            return (
              <div>{ data['complaints'] === 0 ? '-' : data['complaints'] }</div>
            )
          })
        }
        <div style={{fontWeight: "bold"}}>Rejects</div>
        {
          awsSesData['stats'].map(data => {
            return (
              <div>{ data['rejects'] === 0 ? '-' : data['rejects'] }</div>
            )
          })
        }
      </div>
    </div>
  );
}

export default AWSEmailData;
