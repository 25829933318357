import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { subscribe, unsubscribe } from '../events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


function GrantsReport()
{
    const [loaded, setLoaded] = useState(false)
    const [displayDate, setDisplayDate] = useState('')
    const [running, setRunning] = useState(false)
    const [link, setLink] = useState('')

    const fetchData = () => {
        var fUrl = process.env.REACT_APP_API_END_POINT + 'admingrantsreport/';
        fetch(fUrl,
        {
            headers: {
                'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                'mode' : 'check_date'
            })
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                setLoaded(true)
                setDisplayDate(new Date(data['last_run'] * 1000).toLocaleString())
                setRunning(false)
                setLink(data['link'])
            } else {
            
            }
        });
    }
    
    const runNow = () => {
        setRunning(true)
        var fUrl = process.env.REACT_APP_API_END_POINT + 'admingrantsreport/';
        fetch(fUrl,
        {
            headers: {
                'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                'mode' : 'run_now'
            })
        })
        .then((resp) => resp.json())
        .then((data) => {
            
        });
    }

    const downloadNow = () => {
        window.open(link, '_blank', 'noopener,noreferrer');   
    }

    // on component mount, fetch the data for the dashboard
    useEffect(() => {
        fetchData()
        const handleReportUpdatedMessage = (body) => {
            fetchData()
        }
        subscribe('grantsReportGenerated', handleReportUpdatedMessage)
        return () => {
            unsubscribe('grantsReportGenerated', handleReportUpdatedMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        {loaded && <>
            <h1 class="adminDashboard">Export grants report</h1>
            <p>
                Last generated: {displayDate}
            </p>
            <Button id="runGrantReportButton" disabled={running} size="sm" onClick={runNow}>{running ? <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#FFFFFF"}} /> : <>Run now</>}</Button>
            &nbsp;
            {!running && <Button id="downloadGrantReportButton" size="sm" onClick={downloadNow}>Download</Button>}
        </>}
        {!loaded && <>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        </>}
        </>
    );
}

export default GrantsReport;