import React , { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';

function LoginPage(props) {

  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState(location?.state?.username ?? "");
  const [password, setPassword] = useState("");
  const [show2faQR, setShow2faQR] = useState(false);
  const [qrData, setQRData] = useState("");
  const [error, setError] = useState("");
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [tfaCode, setTFACode] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const doLogin = (event) => {
    setShow2faQR(false);
    setLoginButtonDisabled(true);
    setError("Wait...");
    const url = process.env.REACT_APP_API_END_POINT + 'login/?admin=yes';
    var submittedData = {
      username, password, tfaCode
    };
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(submittedData),
        credentials: "include"
      })
      .then((resp) => resp.json())
      .then((data) => {
        setLoginButtonDisabled(false);
        if (data['success']) {
          props.setIsLoggedIn(true);
          // props.setUserRole(data['role']);
          navigate('/incompleteReports');
        } else {
          if (data['error'] === 'CONFIRM_2FA') {
            setShow2faQR(true);
            setQRData(data['qr_data']);
            setError("")
          } else {
            setError(data['error']);
          }
        }
      });
    event.preventDefault();
  }

  useEffect(() => {
    if (props.isLoggedIn) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column">
        <img width="360" src="/adminLogo.png" alt="Admin logo" />
        <div className="card" style={{ width: '25rem' }}>
          <div className="card-header"><strong>Sign in</strong></div>
          <div className="card-body">
            <form onSubmit={doLogin}>
              <div className="form-group">
                <label htmlFor="usernameInput">Username</label>
                <input id="usernameInput" className="form-control" type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
              </div>
              <div className="form-group">
                <label htmlFor="passwordInput">Password</label>
                <input id="passwordInput" className="form-control" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
              </div>
              { show2faQR ? 
                <div>
                  This is the first time you are logging in to your account. Please download an authentication app, use it to scan the below QR code and then enter the code given by the app.
                  <QRCode size={256} level={"Q"} value={qrData} />
                </div>
                :
                null
              }
              <div className="form-group">
                <label htmlFor="twoFactorInput">Authentication code</label>
                <input id="twoFactorInput" className="form-control" type="text" placeholder="" value={tfaCode} onChange={(e) => setTFACode(e.target.value)} />
              </div>
              <div className="form-group">
                <button id="loginButton" type="submit" disabled={loginButtonDisabled} className="btn btn-primary btn-sm">Login</button>&nbsp;<span>{error}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );


}

export default LoginPage;